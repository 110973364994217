import React from 'react';
import ToBackTopNav from '../components/top_nav/ToBackTopNav';
import Container from './stracture/Container';

class Privacy extends React.Component {

    render() { 
        return ( <>
        <ToBackTopNav title="Privacy"/>
                <Container>
                      


        </Container>
        

        </> ); 
    }
}
 
export default Privacy;