import React from 'react';
import ToBackTopNav from '../components/top_nav/ToBackTopNav';

import Container from './stracture/Container';

class Profile extends React.Component {

    render() { 
        return ( <>
        <ToBackTopNav title="Profile"/>
                <Container>
                      
        </Container>
        

        </> ); 
    }
}
 
export default Profile;