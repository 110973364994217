import React from 'react';

import ToBackTopNav from '../components/top_nav/ToBackTopNav';
import Container from './stracture/Container';

class Comment extends React.Component {

    render() { 
        return ( <>
        <ToBackTopNav title="Comment"/>
                <Container>
                    
        </Container>
        

        </> ); 
    }
}
 
export default Comment;